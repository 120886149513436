
import { createRouter, createWebHashHistory } from 'vue-router';

import { useAuth } from 'src/composables/auth';


function passRouteToProps(route){
	return {
		queryParams: route.query,
		fieldName: route.params.fieldName, 
		fieldValue: route.params.fieldValue
	}
}


let routes = [
	//Dashboard routes


//applications routes
			{
				path: '/applications/:fieldName?/:fieldValue?',
				name: 'applicationslist',
				component: () => import('./pages/applications/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/applications/view/:id', 
				name: 'applicationsview', 
				component: () => import('./pages/applications/view.vue'), 
				props: true
			},
		
			{ 
				path: '/applications/add', 
				name: 'applicationsadd', 
				component: () => import('./pages/applications/add.vue'), 
				props: true
			},
	
			{ 
				path: '/applications/edit/:id', 
				name: 'applicationsedit', 
				component: () => import('./pages/applications/edit.vue'), 
				props: true
			},
		
			{
				path: '/applications/pending/:fieldName?/:fieldValue?',
				name: 'applicationspending',
				component: () => import('./pages/applications/pending.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{
				path: '/applications/accepted/:fieldName?/:fieldValue?',
				name: 'applicationsaccepted',
				component: () => import('./pages/applications/accepted.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{
				path: '/applications/bdspending/:fieldName?/:fieldValue?',
				name: 'applicationsbdspending',
				component: () => import('./pages/applications/bdspending.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{
				path: '/applications/bdsreviewed/:fieldName?/:fieldValue?',
				name: 'applicationsbdsreviewed',
				component: () => import('./pages/applications/bdsreviewed.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{
				path: '/applications/bdsassigned/:fieldName?/:fieldValue?',
				name: 'applicationsbdsassigned',
				component: () => import('./pages/applications/bdsassigned.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/applications/bdsview/:id', 
				name: 'applicationsbdsview', 
				component: () => import('./pages/applications/bdsview.vue'), 
				props: true
			},
		
			{ 
				path: '/applications/bdsedit/:id', 
				name: 'applicationsbdsedit', 
				component: () => import('./pages/applications/bdsedit.vue'), 
				props: true
			},
			
			{ 
				path: '/applications/pendingsubmission/:fieldName?/:fieldValue?', 
				name: 'applicationspendingsubmission', 
				component: () => import('./pages/applications/pendingsubmission.vue'), 
				props: true
			},
		

//applicationservice routes
			{
				path: '/applicationservice/:fieldName?/:fieldValue?',
				name: 'applicationservicelist',
				component: () => import('./pages/applicationservice/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/applicationservice/view/:id', 
				name: 'applicationserviceview', 
				component: () => import('./pages/applicationservice/view.vue'), 
				props: true
			},
		
			{ 
				path: '/applicationservice/add', 
				name: 'applicationserviceadd', 
				component: () => import('./pages/applicationservice/add.vue'), 
				props: true
			},
	
			{ 
				path: '/applicationservice/edit/:id', 
				name: 'applicationserviceedit', 
				component: () => import('./pages/applicationservice/edit.vue'), 
				props: true
			},
		

//audits routes
			{
				path: '/audits/:fieldName?/:fieldValue?',
				name: 'auditslist',
				component: () => import('./pages/audits/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/audits/view/:id', 
				name: 'auditsview', 
				component: () => import('./pages/audits/view.vue'), 
				props: true
			},
		

//comments routes
			{
				path: '/comments/:fieldName?/:fieldValue?',
				name: 'commentslist',
				component: () => import('./pages/comments/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/comments/view/:id', 
				name: 'commentsview', 
				component: () => import('./pages/comments/view.vue'), 
				props: true
			},
		
			{ 
				path: '/comments/add', 
				name: 'commentsadd', 
				component: () => import('./pages/comments/add.vue'), 
				props: true
			},
	
			{ 
				path: '/comments/edit/:id', 
				name: 'commentsedit', 
				component: () => import('./pages/comments/edit.vue'), 
				props: true
			},
		

//documents routes
			{
				path: '/documents/:fieldName?/:fieldValue?',
				name: 'documentslist',
				component: () => import('./pages/documents/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/documents/view/:id', 
				name: 'documentsview', 
				component: () => import('./pages/documents/view.vue'), 
				props: true
			},
		
			{ 
				path: '/documents/add', 
				name: 'documentsadd', 
				component: () => import('./pages/documents/add.vue'), 
				props: true
			},
	
			{ 
				path: '/documents/edit/:id', 
				name: 'documentsedit', 
				component: () => import('./pages/documents/edit.vue'), 
				props: true
			},
		

//owners routes
			{
				path: '/owners/:fieldName?/:fieldValue?',
				name: 'ownerslist',
				component: () => import('./pages/owners/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/owners/view/:id', 
				name: 'ownersview', 
				component: () => import('./pages/owners/view.vue'), 
				props: true
			},
		
			{ 
				path: '/owners/add', 
				name: 'ownersadd', 
				component: () => import('./pages/owners/add.vue'), 
				props: true
			},
	
			{ 
				path: '/owners/edit/:id', 
				name: 'ownersedit', 
				component: () => import('./pages/owners/edit.vue'), 
				props: true
			},
		

//permissions routes
			{
				path: '/permissions/:fieldName?/:fieldValue?',
				name: 'permissionslist',
				component: () => import('./pages/permissions/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/permissions/view/:id', 
				name: 'permissionsview', 
				component: () => import('./pages/permissions/view.vue'), 
				props: true
			},
		
			{ 
				path: '/permissions/add', 
				name: 'permissionsadd', 
				component: () => import('./pages/permissions/add.vue'), 
				props: true
			},
	
			{ 
				path: '/permissions/edit/:id', 
				name: 'permissionsedit', 
				component: () => import('./pages/permissions/edit.vue'), 
				props: true
			},
		

//progress routes
			{
				path: '/progress/:fieldName?/:fieldValue?',
				name: 'progresslist',
				component: () => import('./pages/progress/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/progress/view/:id', 
				name: 'progressview', 
				component: () => import('./pages/progress/view.vue'), 
				props: true
			},
		
			{ 
				path: '/progress/add', 
				name: 'progressadd', 
				component: () => import('./pages/progress/add.vue'), 
				props: true
			},
	
			{ 
				path: '/progress/edit/:id', 
				name: 'progressedit', 
				component: () => import('./pages/progress/edit.vue'), 
				props: true
			},
		

//roles routes
			{
				path: '/roles/:fieldName?/:fieldValue?',
				name: 'roleslist',
				component: () => import('./pages/roles/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/roles/view/:id', 
				name: 'rolesview', 
				component: () => import('./pages/roles/view.vue'), 
				props: true
			},
		
			{ 
				path: '/roles/add', 
				name: 'rolesadd', 
				component: () => import('./pages/roles/add.vue'), 
				props: true
			},
	
			{ 
				path: '/roles/edit/:id', 
				name: 'rolesedit', 
				component: () => import('./pages/roles/edit.vue'), 
				props: true
			},
		

//scores routes
			{
				path: '/scores/:fieldName?/:fieldValue?',
				name: 'scoreslist',
				component: () => import('./pages/scores/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/scores/view/:id', 
				name: 'scoresview', 
				component: () => import('./pages/scores/view.vue'), 
				props: true
			},
		
			{ 
				path: '/scores/add', 
				name: 'scoresadd', 
				component: () => import('./pages/scores/add.vue'), 
				props: true
			},
	
			{ 
				path: '/scores/edit/:id', 
				name: 'scoresedit', 
				component: () => import('./pages/scores/edit.vue'), 
				props: true
			},
		

//services routes
			{
				path: '/services/:fieldName?/:fieldValue?',
				name: 'serviceslist',
				component: () => import('./pages/services/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/services/view/:id', 
				name: 'servicesview', 
				component: () => import('./pages/services/view.vue'), 
				props: true
			},
		
			{ 
				path: '/services/add', 
				name: 'servicesadd', 
				component: () => import('./pages/services/add.vue'), 
				props: true
			},
	
			{ 
				path: '/services/edit/:id', 
				name: 'servicesedit', 
				component: () => import('./pages/services/edit.vue'), 
				props: true
			},
		

//users routes
			{
				path: '/users/:fieldName?/:fieldValue?',
				name: 'userslist',
				component: () => import('./pages/users/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/users/view/:id', 
				name: 'usersview', 
				component: () => import('./pages/users/view.vue'), 
				props: true
			},
		
			{ 
				path: '/index/register', 
				name: 'usersuserregister', 
				component: () => import('./pages/index/userregister.vue'), 
				props: true
			},
	
			{ 
				path: '/account/edit', 
				name: 'usersaccountedit', 
				component: () => import('./pages/account/accountedit.vue'), 
				props: true
			},
	
			{ 
				path: '/account', 
				name: 'usersaccountview', 
				component: () => import('./pages/account/accountview.vue'), 
				props: true
			},
	
			{ 
				path: '/users/add', 
				name: 'usersadd', 
				component: () => import('./pages/users/add.vue'), 
				props: true
			},
	
			{ 
				path: '/users/edit/:id', 
				name: 'usersedit', 
				component: () => import('./pages/users/edit.vue'), 
				props: true
			},
		

	
//Otp verify code routes
			{ 
				path: '/index/verifyotp', 
				name: 'verifyotp', 
				component: () => import('./pages/index/verifyotp.vue'), 
				props: true
			},
	
	
//Password reset routes
			{ 
				path: '/index/forgotpassword', 
				name: 'forgotpassword', 
				component: () => import('./pages/index/forgotpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword', 
				name: 'resetpassword', 
				component: () => import('./pages/index/resetpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword_completed', 
				name: 'resetpassword_completed', 
				component: () => import('./pages/index/resetpassword_completed.vue'), 
				props: true
			},
	
	
	
		{ 
			path: '/index/accountcreated', 
			name: 'accountcreated', 
			component: () => import('./pages/index/accountcreated.vue'), 
			props: true
		},
		{
			path: '/index/accountpending', 
			name: 'accountpending', 
			component: () => import('./pages/index/accountpending.vue'), 
			props: true
		},
		{ 
			path: '/index/accountblocked',
			name: 'accountblocked', 
			component: () => import('./pages/index/accountblocked.vue'), 
			props: true
		},

	{ 
		path:  '/error/forbidden', 
		name: 'forbidden', 
		component: () => import('./pages/errors/forbidden.vue'),
		props: true
	},
	{ 
		path: '/error/notfound', 
		name: 'notfound',
		component: () => import('./pages/errors/pagenotfound.vue'),
		props: true
	},
	{
		path: '/:catchAll(.*)', 
		component: () => import('./pages/errors/pagenotfound.vue')
	}
];

export default () => {
	
const auth = useAuth();

	
	const user = auth.user;
	if(user){
		const roleName = auth.userRole.toLowerCase();
		let rolePage;
		switch(roleName){
			case "manager":
				rolePage = "manager";
				break;
			case "consultant":
				rolePage = "consultant";
				break;
			default:
				rolePage = "home";
		}
		
		//Dashboard route
		//Display page based on user role
		routes.push({
			path: '/',
			alias: '/home', 
			name: 'home', 
			component: () => import(`./pages/home/${rolePage}.vue`),
			props: true
		});
	}
	else{
		routes.push({
			path: '/', 
			alias: '/index', 
			name: 'index', 
			component: () => import('./pages/index/index.vue'),
			props: true
		});
	}

	const router = createRouter({
		history: createWebHashHistory(),
		routes,
		scrollBehavior(to, from, savedPostion){
			if(savedPostion) return savedPostion;
			return { top:0 }
		}
	});
	
	router.beforeEach((to, from, next) => {
		const user = auth.user;
		let path = to.path;
		let authRequired = auth.pageRequiredAuth(path);
		if (authRequired) {
			if(!user){
				return next({ path: '/',  query: { nexturl: to.fullPath } });
			}
			//authorize user
			if (!auth.canView(path)) {
				return next({path: "/error/forbidden"});
			}
		}

		if(user && to.name == "index"){
			//already logged in, show home when try to access index page
			return next({ path: "/home"});
		}

		//navigate to redirect url if available
		if(to.name == "home" && to.query.nexturl){
			return next({ path: to.query.nexturl});
		}

 		//close dialog from previous page
		//store.closeDialogs('app/closeDialogs');
		
		next();
	});

	return router;
}
